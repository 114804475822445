import lotteryImage from './lottery.png'
import IFOImage from './IFO.png'
import IFOMobileImage from './IFOMobile.png'
import competitionImage from './competition.png'
import competitionMobileImage from './competitionMobile.png'
import modImage from './mod-tc-desktop.png'
import modMobileImage from './mod-tc-mobile.png'
import lotteryMobileImage from './lotteryMobile.png'
import modWhiteLogo from './mod-white-logo.png'
import perpetualImage from './perpetual.png'
import perpetualMobileImage from './perpetualMobile.png'
import Aptos from './Aptos.png'
import AptosXPancakeSwap from './AptosXPancakeSwap.png'
import trustwalletBg from './trustwalletBg.png'
import trustwalletBunny from './trustwalletBunny.png'

export {
  lotteryImage,
  IFOImage,
  competitionImage,
  modImage,
  modMobileImage,
  lotteryMobileImage,
  competitionMobileImage,
  IFOMobileImage,
  modWhiteLogo,
  perpetualImage,
  perpetualMobileImage,
  Aptos,
  AptosXPancakeSwap,
  trustwalletBg,
  trustwalletBunny,
}
